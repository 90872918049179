import { COLORS } from 'common/const/colors.const'
import styled from 'styled-components'

export const PagesWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  min-width: 960px;
  background: #eee;
  display: block;
  padding-bottom: 100px;
`

export const PagesContainer = styled.div`
  display: flex;
  max-width: 800px;
  min-height: 100vh;
  margin: 0 auto;
  padding-top: 80px;
  background: ${COLORS.WHITE};
  border-radius: 4px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.14);
`

export const PagesContainerRegistration = styled.div`
  display: flex;
  max-width: 800px;
  min-height: 100vh;
  margin: 0 auto;
  background: ${COLORS.WHITE};
  border-radius: 4px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.14);
`
