import { COLORS } from 'common/const/colors.const'
import styled from 'styled-components'

export const TextAreaWrapper = styled.div<{ width?: number }>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => `${width}px`};
`

export const TextAreaContainer = styled.div`
  border: solid 1px ${COLORS.GRAY};
  border-radius: 2px;
`

export const TextAreaField = styled.textarea<{ height: number }>`
  height: ${({ height }) => `${height}px`};
  resize: none;
  padding: 10px;
  border: none;
  font-family: HelveticaNeue, sans-serif;
  font-size: 14px;
  width: 100%;
  &:focus {
    outline: none;
  }
`

export const Label = styled.label`
  font-family: HelveticaNeue, sans-serif;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 2px;
`
